/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var BrowserDetect = {
      init: function () {
          this.browser = this.searchString(this.dataBrowser) || "Other";
          this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
      },
      searchString: function (data) {
          for (var i = 0; i < data.length; i++) {
              var dataString = data[i].string;
              this.versionSearchString = data[i].subString;

              if (dataString.indexOf(data[i].subString) !== -1) {
                  return data[i].identity;
              }
          }
      },
      searchVersion: function (dataString) {
          var index = dataString.indexOf(this.versionSearchString);
          if (index === -1) {
              return;
          }

          var rv = dataString.indexOf("rv:");
          if (this.versionSearchString === "Trident" && rv !== -1) {
              return parseFloat(dataString.substring(rv + 3));
          } else {
              return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
          }
      },

      dataBrowser: [
          {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
          {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
          {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
          {string: navigator.userAgent, subString: "Opera", identity: "Opera"}
      ]

  };

BrowserDetect.init();

Modernizr.addTest('svgasimg', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));

function printMenu(id) {
  var printableMenu = window.open('', 'PRINT');

  var prepMenu = function(id) {
    var r = $.Deferred();
    var $stylesheets = $('head').find('link[rel="stylesheet"]');
    printableMenu.document.write('<html><head>');

    $stylesheets.each(function() {
      if ($(this).attr('href')) {
        printableMenu.document.write('<link rel="stylesheet" href="' + $(this).attr('href') + '" type="text/css" />');
      }
    });

    printableMenu.document.write("<script id='webfont'>WebFontConfig = {typekit: { id: 'zps0ehh' }, google: {families: [ 'Alice::latin' ]}, }; var cb = function() { var ref = document.getElementById('webfont'); var wf = document.createElement('script'); wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'; wf.type = 'text/javascript'; wf.async = 'true'; ref.parentNode.insertBefore( wf, ref ); }; window.requestAnimationFrame(cb); </script>");
    printableMenu.document.write('<title>' + document.title + '</title>');
    printableMenu.document.write('</head><body class="wf-active printable">');
    printableMenu.document.write('<img width="160" class="logo" src="/wp-content/themes/butchart/dist/images/logo.svg" alt="Logo">');
    printableMenu.document.write('<h1>' + $('.hero h1 .heading__text').text() + '</h1>');
    printableMenu.document.write($('.loader[data-menu-id="' + id + '"]').html());
    printableMenu.document.write('</body></html>');

    return r;
  };

  var printMenu = function() {
    setTimeout(function() {
      printableMenu.document.close();
      printableMenu.print();
    }, 300);
  };

  prepMenu(id).done( printMenu() );

  return true;
}

// Do things

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('body').addClass('is-' + BrowserDetect.browser.toLowerCase());
        $('body').addClass('is-' + BrowserDetect.browser.toLowerCase() + '-' + BrowserDetect.version);
        // JavaScript to be fired on all pages
        svg4everybody();

        // Edge pace hack
        setTimeout(function() {
          console.log('loaded');
          Pace.stop();
          $('body').removeClass('pace-running');
          $('body').addClass('pace-done');
        }, 50);

        if(!Modernizr.touchevents){
          $("a[href*='tel:']").on('click',function(){
            return false;
          });
        }
        $("[data-block], a[href^='#']").on('click',function(){
          var $el = $($(this).data('block') || $(this).attr('href'));

          if($el.length > 0) {
            $("html").velocity("scroll", { offset: $el.offset().top, mobileHA: false });
            return false;
          }
        });

        // Hash scroll
        if(window.location.hash && $(window.location.hash).length > 0) {
          // console.log(window.location.hash);
          $(window.location.hash).velocity("scroll");
        }

        $.simpleWeather({
          location: '48.562490,-123.466613',
          woeid: '',
          unit: 'c',
          success: function(weather) {
            $('.weather__c').text(weather.temp);
            $('.weather__f').text(weather.alt.temp);
            $('.weather').removeClass('is-hidden');
          },
          error: function(error) {
            console.log(error);
          }
        });


        $("a.video").magnificPopup({
          type:'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 300,
           callbacks:{
            open:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.pause();
              }
            },
            close:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.play();
              }
            }
          },
          iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                      '<div class="mfp-close"></div>'+
                      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
              youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
              },
              gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed'
              }
            }
          }
        });

        //fire the map if it exists
        var gmapScript ='https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit&key='+GOOGLE_API_KEY;
        if($("#map-canvas").length !== 0){
          $.getScript( gmapScript )
            .done(function( script, textStatus ) {
              //console.log( script );
            })
            .fail(function( jqxhr, settings, exception ) {
              //$( "div.log" ).text( "Triggered ajaxError handler." );
          });
        }

      },
      finalize: function() {
        objectFitImages();

        stickyHeaderInit();

        $("nav.greedy").makeGreedy();

        if ($('.content-block.has-flowers').length) {
          someParallax({
            target: '.content-block.has-flowers',
            activeClass: 'has-parallax',
            layoutTrigger: {
              property: 'overflow',
              value: 'visible'
            },
            scenes: {
              floater_left: {
                target: '.floater--0 picture',
                tween: {
                  x: -1000,
                  y: 150,
                  opacity: 0.4
                },
                easing: Circ.easeInOut
              },
              floater_right: {
                target: '.floater--1 picture',
                tween: {
                  x: 1000,
                  y: 150,
                  opacity: 0.4
                },
                easing: Circ.easeInOut
              }
            }
          });
        }

        // We probably don't need this right away
        $('.js-slider').each(function() {
          var visibleSlides = $(this).data('visible-slides');
          var pagination    = $(this).data('pagination');
          var navigation    = $(this).data('navigation');
          var loop          = $(this).data('loop');
          var autoplay      = $(this).data('autoplay');
          var params        = {
            direction: 'horizontal',
            loop: loop,
            slidesPerView: visibleSlides,
            speed: 600,
            paginationClickable: true,
            spaceBetween: 0
          };

          if (pagination) {
            params.pagination = $(this).find('.swiper-pagination');
          }

          if (autoplay) {
            params.autoplay = autoplay;
          }

          if (navigation) {
            params.nextButton = $(this).find('.swiper-next');
            params.prevButton = $(this).find('.swiper-prev');
          }

          if (visibleSlides > 1) {
            params.breakpoints = {
              320: {
                slidesPerView: 1
              }
            };

            if (visibleSlides > 2) {
              params.breakpoints['640'] = {
                slidesPerView: 2
              };
            }

            if (visibleSlides > 3) {
              params.breakpoints['768'] = {
                slidesPerView: 3
              };
            }
          }

          var swiper = new Swiper ($(this).find('.swiper-container'), params);
        });

        $('.js-testimonials').each(function() {
          var params = {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            autoplay: 10000,
            speed: 1200,
            pagination: $(this).find('.swiper-pagination'),
            paginationClickable: true,
            spaceBetween: 0,
            nextButton: $(this).find('.swiper-next'),
            prevButton: $(this).find('.swiper-prev')
          };

          var testimonialSwiper = new Swiper($(this).find('.swiper-container'), params);
        });

        $('.events').each(function () {
          var params = {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 3,
            speed: 600,
            pagination: $(this).find('.swiper-pagination'),
            paginationClickable: true,
            spaceBetween: 30,
            nextButton: $(this).find('.swiper-next'),
            prevButton: $(this).find('.swiper-prev'),
            breakpoints: {
              767: {
                slidesPerView: 1
              },
              1600: {
                slidesPerView: 2
              }
            }
          };

          var eventSwiper = new Swiper($(this).find('.swiper-container'), params);
        });

        $('.columns').each(function () {
          var params = {
            direction: 'horizontal',
            loop: false,
            slidesPerView: $(this).data('columns'),
            speed: 600,
            pagination: $(this).find('.swiper-pagination'),
            paginationClickable: true,
            spaceBetween: 80,
            nextButton: $(this).find('.swiper-next'),
            prevButton: $(this).find('.swiper-prev'),
            simulateTouch: false,
            breakpoints: {
              550: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              1249: {
                slidesPerView: $(this).data('columns'),
                spaceBetween: 20
              },
              1439: {
                slidesPerView: $(this).data('columns'),
                spaceBetween: 60
              },
              1920: {
                slidesPerView: $(this).data('columns'),
                spaceBetween: 80
              }
            }
          };

          if ($(this).data('columns') >= 2) {
            params.breakpoints['959'] = {
              slidesPerView: 2,
              spaceBetween: 20
            };
          }

          var columnSwiper = new Swiper($(this).find('.swiper-container'), params);
        });

        $('.tickets').each(function() {
          $(this).find('.column__toggle').each(function() {
            $(this).on('click', function() {
              var $target = $(this).parent().siblings('.tickets__column__info');

              $('.tickets__column__info').not($target).slideUp();
              $target.slideToggle();
            });
          });
        });

        var loaders = [];

        if ($('a[data-loader]').length) {
          $('a[data-loader]').each(function() {
            var loaderID = $(this).data('loader');
            var loaderData = {
              triggerData: loaderID
            };

            if ($(this).attr('data-content')) {
              loaderData.updateContent = true;
            }

            loaders[loaderID] = new Loader('.loader--' + loaderID, loaderData);
          });
        }

        $('.js-buy-tickets').on('click', function() {
          $('.ticketbox__column--e-tickets .ticketbox__column__inner > .button').attr('href', $(this).data('link-1'));
          $('.ticketbox__column--mailtickets .ticketbox__column__inner > .button').attr('href', $(this).data('link-2'));
          console.log($(this).data('link-1'));
          console.log($(this).data('link-2'));
        });

        $(window).load(function() {
          if ($('.alert').length) {
            $('body').addClass('alert-active');
            $('.region--header').css('top', $('.alert').outerHeight());

            $(window).resize(function() {
              if ($('body').hasClass('alert-active')) {
                $('.region--header').css('top', $('.alert').outerHeight());
              }
            });

            $('.alert__close').on('click', function() {
              $('body').removeClass('alert-active');
              $('.region--header').css('top', 0);
            });
          }
        });

        var menuLoaderMobile = new Loader('.nav--mobile--primary', {
          trigger: '.nav__toggle--mobile'
        });

        var menuLoaderDesktop = new Loader('.navbox', {
          trigger: '.nav__toggle--desktop'
        });

        $('.navbox__close').on('click', function() {
          if ($('.language-switcher').hasClass('is-visible')) {
            $('.language-switcher').removeClass('is-visible');
          }
        });

        $(window).resize(function() {
          if (menuLoaderDesktop.state === 'open' && $('.nav__toggle--desktop').css('display') === 'none') {
            menuLoaderDesktop.close();
            menuLoaderMobile.open();
          }
          if (menuLoaderMobile.state === 'open' && $('.nav__toggle--mobile').css('display') === 'none') {
            menuLoaderMobile.close();
            menuLoaderDesktop.open();
          }
        });

        if ($('.loader--popup').length) {
          var popupLoader = new Loader('.loader--popup');
          if (popupLoader.loader.data('delay')) {
            setTimeout(function() { popupLoader.toggle(); }, popupLoader.loader.data('delay'));
          }
        }

        // Mobiles
        mobileNavInit();

        // Spaces loader
        spacesInit();

        //Js
        var magicController = new ScrollMagic.Controller();

        $('.is-magic:not(.is-latent)').each(function(){
          var that = this;

          var scene = new ScrollMagic.Scene({
            triggerElement: that,
            triggerHook: 0.95
          })
          .setClassToggle(that, "scroll-triggered")
          .addTo(magicController);
        });

        $('.stats-block').each(function() {
          $(this).find('.stat__number__content').each(function(i) {
            var that  = this;
            var value = $(this).text();
            var delay = i * 300;
            $(this).attr('data-value', value);
            var countStat = new CountUp(this, 0, value, 0, 2.5, {
              useEasing: true,
              useGrouping: true,
              separator: ',',
              decimal: '.'
            });
        

            if (!countStat.error) {
              var scene = new ScrollMagic.Scene({
                triggerElement: that,
                triggerHook: 0.95
              })
              .on('start', function() {
                countStat.reset();

                setTimeout(function() {
                  countStat.start();
                }, delay);
              })
              .addTo(magicController);
            } else {
              console.error(countStat.error);
            }
          });
        });

        $('.is-magic-box').each(function(){
          var box = this;

          $(this).find('.is-magic.is-latent').each(function() {
            var that = this;

            var scene = new ScrollMagic.Scene({
              triggerElement: box,
              triggerHook: 0.95
            })
            .setClassToggle(that, "scroll-triggered")
            .addTo(magicController);
          });
        });

        $('#instafeed').each(function() {
          var target = this;
          var $target = $(target);
          var instafeed_tag = 0;
          instafeed_tag = $(this).data('instagram-tag');
          if (instafeed_tag != null) {
            var userFeed = new Instafeed({
              get: 'user',
              userId: '564073978',
              //clientId:  'f100d4bcd3724b889afe3419c1491384',
              accessToken: '564073978.d565032.99c940b6e5b54b77927d62ceae401d4a',
              limit: 100,
              resolution: 'standard_resolution',
              before: function () {
                currentCount = 0;
              },
              filter: function (image) {
                var shouldDisplay = (currentCount < 8);
                if (shouldDisplay) {
                  if (image.tags && image.tags.indexOf(instafeed_tag ? instafeed_tag : 0) >= 0) {
                    currentCount += 1;
                  } else {
                    shouldDisplay = false;
                  }
                }
                return shouldDisplay;
              },
              after: function() {
                $target.find('.is-magic.is-latent').each(function(index) {
                  $(this).addClass('delay--' + index);

                  var that = this;

                  var scene = new ScrollMagic.Scene({
                    triggerElement: target,
                    triggerHook: 0.95
                  })
                  .setClassToggle(that, "scroll-triggered")
                  .addTo(magicController);
                });
              },
              template: '<a href="{{link}}" target="_blank" class="instagram-feed__img is-magic is-latent"><img data-src="{{image}}" data-expand="500" class="lazyload fade-lazyload" /></a>'
            });
            userFeed.run();
          } else {
            var userFeed2 = new Instafeed({
              get: 'user',
              userId: '564073978',
              //clientId:  'f100d4bcd3724b889afe3419c1491384',
              accessToken: '564073978.d565032.99c940b6e5b54b77927d62ceae401d4a',
              limit: 8,
              resolution: 'standard_resolution',
              after: function() {
                $target.find('.is-magic.is-latent').each(function(index) {
                  $(this).addClass('delay--' + index);

                  var that = this;

                  var scene = new ScrollMagic.Scene({
                    triggerElement: target,
                    triggerHook: 0.95
                  })
                  .setClassToggle(that, "scroll-triggered")
                  .addTo(magicController);
                });
              },
              template: '<a href="{{link}}" target="_blank" class="instagram-feed__img is-magic is-latent"><img data-src="{{image}}" data-expand="500" class="lazyload fade-lazyload" /></a>'
            });
            userFeed2.run();
          }
        });

        //Disable append a per client request.
        //$('.nav--mobile__secondary').append('<li class="menu-item menu-language"><a href="#0" class="menu__language">Language</a></li>');

        $('.menu__language').click(function() {
          $(this).parents('.nav--mobile').find('.language-switcher').toggleClass('is-visible');
        });

        $('.navbox__language').click(function() {
          $(this).siblings('.language-switcher').toggleClass('is-visible');
        });

        $('.js-language-switcher__close').click(function() {
          $(this).parents('.nav--mobile').find('.language-switcher').toggleClass('is-visible');
        });

        // $('.language-switcher a').click(function() {
        //   $(this).parents('.language-switcher').toggleClass('is-visible');
        // });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

            // var hero = $('.hero').vide('{URL GOES HERE}', {
            //   volume: 0,
            //   playbackRate: 1,
            //   muted: true,
            //   loop: true,
            //   autoplay: true,
            //   position: '50% 50%', // Similar to the CSS `background-position` property.
            //   posterType: 'none', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
            //   resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
            //   bgColor: 'black', // Allow custom background-color for Vide div,
            //   className: 'video' // Add custom CSS class to Vide div
            // });
            // var resizeVideo = function(){
            //   hero.resize();
            // };
            // requestAnimationFrame(resizeVideo);

        $('.hero.has-slider').each(function () {
          var params = {
            direction: 'horizontal',
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            loop: true,
            slidesPerView: 1,
            autoplay: 5000,
            speed: 1200,
            pagination: $(this).find('.swiper-pagination'),
            paginationClickable: true,
            spaceBetween: 0
          };

          var heroSwiper = new Swiper($(this).find('.swiper-container'), params);
        });
      },
      finalize: function() {

      }
    },
    // About us page, note the change from about-us to about_us.
    'not_front': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.js-gallery__item').magnificPopup({
          type: 'image',
          mainClass: 'mfp-fade',
          removalDelay: 300,
          gallery:{
            enabled:true
          },
          image: {
            markup: '<div class="mfp-figure">'+
                      '<div class="mfp-close"></div>'+
                      '<div class="mfp-img"></div>'+
                      '<div class="mfp-bottom-bar">'+
                        '<div class="mfp-title"></div>'+
                        '<div class="mfp-counter"></div>'+
                      '</div>'+
                    '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

            //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
            // Or the function that should return the title. For example:
            titleSrc: function(item) {
              //console.log($(item.el).find('img').attr('title'));
              return $(item.el).find('img').attr('title');
            },

            verticalFit: true, // Fits image in area vertically

            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
          },
          callbacks: {
            open: function() {
              $.magnificPopup.instance.next = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('next');
                setTimeout(function() {
                  $.magnificPopup.proto.next.call(self);
                }, 400);
              };
              $.magnificPopup.instance.prev = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('prev');
                setTimeout(function() {
                  $.magnificPopup.proto.prev.call(self);
                }, 400);
              };
            },
            imageLoadComplete: function() {
              var self = this;
              self.wrap.addClass('mfp-image-loading');
              setTimeout(function() {
                self.wrap.removeClass('mfp-image-loading prev next');
                self.wrap.addClass('mfp-image-loaded');
              }, 10);
            }
          }
        });

      }
    },
    'our_team': {
      init: function() {

      },
       finalize: function() {

       }
    },
    'contact': {
      init: function() {

      },
       finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          setTimeout(function(){
            UTIL.fire(classnm, 'finalize');
          },200);
        });
        // Fire common finalize JS
        setTimeout(function(){
          UTIL.fire('common', 'finalize');
          console.info( "main.js file has fired all commands." );
        },200);

    }
  };

  //fool the shit out of pagespeed insights
  function loadTheCSS(){
    var stylesheet = loadCSS( cssFile, document.getElementById("css-ref") );
    onloadCSS( stylesheet, function() {
      console.info( "Stylesheet has loaded." );
      $(document).ready(UTIL.loadEvents);
    });
  }
  var botPattern = "(Insights)";
  var re = new RegExp(botPattern, 'i');
  var userAgent = navigator.userAgent;
  if (re.test(userAgent)) {
      window.setTimeout(loadTheCSS, 5000);
  }else{
    loadTheCSS();
  }

})(jQuery); // Fully reference jQuery after this point.
