
function spacesInit() {
  // Animations
  $('[data-space]').hoverIntent({
    over: function(){
      var space = $(this).data('space');
      var $list_item = $('.spaces-map__list__link[data-space="' + space + '"]');
      var $list_item_header = $list_item.parents('.accordion__content').prev();
      $('[data-space="' + space + '"]').addClass('hover-active');

      if ($list_item_header.hasClass('is-inactive')) {
        $list_item_header.parent().siblings().find('.accordion__header.is-active').removeClass('is-active').addClass('is-inactive').next().slideUp().toggleClass('open-content');
        $list_item_header.toggleClass('is-active').toggleClass('is-inactive').next().slideToggle().toggleClass('open-content');
      }
    },
    out:function(){
      var space = $(this).data('space');
      $('[data-space="' + space + '"]').removeClass('hover-active');
    },
    timeout: 50
  });

  $('.spaces-map__header[data-category]').hoverIntent({
    over: function(){
      var category = $(this).data('category');
      $('.spaces-map__pin[data-category="' + category + '"]').addClass('hover-active');
    },
    out:function(){
      var category = $(this).data('category');
      $('.spaces-map__pin[data-category="' + category + '"]').removeClass('hover-active');
    },
    timeout: 50
  });
}
